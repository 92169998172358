import { navigate } from "gatsby"
import React, { ReactElement } from "react"
import Button, { ButtonLink } from "../Button/Button"

export default function DemoCTA(): ReactElement {
  return (
    <div className="flex justify-center bg-br-primary-blue items-center py-4   flex-wrap">
      <div className="text-white font-bold mr-4 text-xl">Get in touch:</div>
      <ButtonLink
        to="/book-a-demo"
        invert
        className="text-lg"
      >
        Book a Demo
      </ButtonLink>
    </div>
  )
}
