import React, { ReactElement } from "react"
import { FreeAccount, GetStarted } from "./Banner"

interface Props {
  hideTitle?: boolean
}

export default function Discover({ hideTitle }: Props): ReactElement {
  return (
    <div className="min-h-[40vh] flex items-center  flex-col text-br-primary-blue text-center px-4">
      {!hideTitle && (
        <h1 className="text-2xl md:text-4xl mt-10">
          Discover the LawLite difference in your practice
        </h1>
      )}
      <div className="mt-20 mb-14 flex flex-col items-center">
        <GetStarted />
        <FreeAccount />
      </div>
    </div>
  )
}
