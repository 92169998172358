import { graphql, Link, PageProps } from "gatsby"
import React, { ReactElement } from "react"
import DefaultLayout from "../templates/DefaultLayout"
import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import MainTextContainer from "../components/MainTextContainer/MainTextContainer"
import Seo from "../components/seo"
import Mike from "../image/team/mike.jpeg"
import Ash from "../image/team/ash.jpeg"
import Cynthi from "../image/team/cynthi.jpeg"
import DemoCTA from "../components/DemoCTA/DemoCTA"
import Subscribe from "../components/HomePage/Subscribe"
import NewsAndUpdates from "../components/HomePage/NewsAndUpdates"
import Discover from "../components/HomePage/Discover"
const team = [
  {
    name: "Ash Kelso | CEO & Co-Founder",
    description: `Ash is the Chief Executive Officer and Co-Founder of LawLite.
    Ash is a Mechatronics Engineer and Lawyer. As an engineer he
    worked in project management for the Department of Defence.
    As a lawyer he has worked in litigation and ADR, and has
    successfully argued at the appellate level. He also has
    significant experience in law practice optimisation through
    technology and process improvement.
    `,
    image: Ash,
  },
  {
    name: "Mike Rahmati | CTO & Co-Founder",
    description: `
    Mike is the Chief Technical Officer and Co-Founder
    of LawLite. Mike brings significant experience as
    a highly respected international cloud security expert
    and successful start-up entrepreneur. Mike is seasoned
    software engineer and previously worked on projects
    for the NSW Department of Justice before co-founding
    Cloud Conformity and now LawLite.
    
    `,
    image: Mike,
  },
  {
    name: "Cynthi Cheung | Business Manager",
    description: `Cynthi is the Business Manager for LawLite. She brings
years of experience in accounting, customer relations
and sales. Cynthi is LawLite's organising queen.
`,
    image: Cynthi,
  },
]
const options: Options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="font-roboto my-4 text-base lg:text-lg ">{children}</p>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="font-roboto my-2 mt-10 font-bold  text-2xl lg:text-4xl text-br-primary-blue">
        {children}
      </h3>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal text-base lg:text-lg ml-10">{children}</ol>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Link to={node.data.uri} className="text-br-primary-blue font-bold">
        {children}
      </Link>
    ),
  },
}

export default function About(
  props: PageProps<{
    allContentfulAbout: {
      nodes: [
        {
          title: string
          body: {
            raw: string
          }
        }
      ]
    }
  }>
): ReactElement {
  const document = props.data?.allContentfulAbout.nodes[0].body.raw
  return (
    <DefaultLayout>
      <Seo title="About Us" />
      <MainTextContainer>
        <h1 className="font-roboto my-2 lg:mt-10 font-bold  text-2xl lg:text-4xl text-br-primary-blue  text-center">
          About Us
        </h1>
        {documentToReactComponents(JSON.parse(document), options)}

        {/* <div className="mt-4">
          <h3 className="mb-6 font-roboto my-2 mt-10 font-bold  text-2xl lg:text-4xl text-br-primary-blue underline">
            Meet the Team
          </h3>
          {team.map((member, index) => {
            return (
              <div
                className={`flex flex-col my-16 items-center lg:items-start ${
                  index % 2 == 1 ? "lg:flex-row-reverse" : "lg:flex-row"
                }`}
                key={member.name}
              >
                <div
                  className={`h-52 w-52 rounded-full overflow-hidden flex-shrink-0 text-center ${
                    index % 2 == 1 ? "lg:ml-10" : "lg:mr-10"
                  }`}
                >
                  <img
                    src={member.image}
                    alt={member.image}
                    className="h-full w-full object-fill"
                  />
                </div>
                <div className="flex flex-col lg:mt-0 mt-6">
                  <div className="text-br-primary-blue font-bold text-xl lg:text-2xl ">
                    {member.name}
                  </div>
                  <div className=" text-base lg:text-lg mt-4">
                    {member.description}
                  </div>
                </div>
              </div>
            )
          })}
        </div> */}
      </MainTextContainer>
      <DemoCTA />
      <Subscribe title="Stay up to date" />
      <NewsAndUpdates />
      <Discover />
    </DefaultLayout>
  )
}

export const AboutQuery = graphql`
  query AboutQuery {
    allContentfulAbout {
      nodes {
        title
        internal {
          content
        }
        body {
          raw
        }
      }
    }
  }
`
